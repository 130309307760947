@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho&display=swap");

/* body {
  overflow-y: hidden;
} */

header h2 {
    font-family: "Shippori Mincho", serif;
    font-size: 1.5rem;
}

header img {
    max-height: 4rem;
}

main {
    margin-bottom: 30%;
}

.loadingFirstPage {
    top: 50vh !important;
}

.home-screen p {
    font-size: 0.9rem;
}

.btn-equal .btn {
    width: 33%;
}

.bi {
    font-size: 1.6rem;
}

.card-body {
    font-size: 0.7rem;
}

.loginLogo {
    max-height: 150px;
}

.change-page {
    left: 27vw;
}

@media screen and (max-width: 768px) {
    .change-page {
        left: 20vw;
    }
}

@media screen and (max-width: 600px) {
    /* body {
        max-height: 95vh;
        max-width: 150vw; */
    /* overflow-y: hidden;
    } */

    .book-canvas {
        /* max-height: 85vh;
        max-width: 150vw; */
        overflow-x: hidden;
    }

    .name-title {
        text-align: start;
        font-size: 1em !important;
    }

    .book-close-btn {
        right: 0 !important;
    }
}

@media screen and (max-width: 450px) {
    .name-title {
        font-size: 0.9em !important;
    }

    .book-canvas {
        height: 93vh !important;
    }

    .change-page {
        left: 0;
    }
}
